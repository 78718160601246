import React from "react"
import Layout from "../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Home = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Eduardo Ceballos"
        description="Sitio web del poeta Eduardo Ceballos"
        canonical="https://eduardoceballos.com/"
        openGraph={{
          url: `https://eduardoceballos.com/`,
          title: `Eduardo Ceballos`,
          description: `Sitio web del poeta Eduardo Ceballos`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/index.jpg`,
              alt: `Sitio web del poeta Eduardo Ceballos`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Vida y Obra.</h1>
      <h6>
        <a href="https://eduardoceballos.com/libros/eldespojado.pdf">
          Ver “El Despojado”
        </a>
      </h6>
      <div>
        <p>
          El escritor y periodista Eduardo Ceballos nació el 25 de mayo de 1947
          en la ciudad de Salta. Cursó sus estudios primarios en la escuela
          Gral. Julio Argentino Roca, ingresando luego al Seminario Conciliar de
          Salta y al Bachillerato Humanista Moderno. Allí recibió una fuerte
          formación humanística que arraigó profundamente en su ser, y que
          inspiró en él profundas ansias de saber y de crecer.
        </p>
        <p>
          Desde muy joven se vinculó a los más reconocidos poetas y músicos
          recorriendo a su lado diferentes escenarios de la Patria. Se destacó
          en el mayor de todos, el “Payo Solá”, donde animó la Serenata a
          Cafayate durante quince años consecutivos. Actuó también en la Fiesta
          de la Vendimia en Animaná, y en fiestas patronales de diferentes
          localidades. Fue animador del Festival Latinoamericano, en el año
          1986. Con Zamba Quipildor realizó giras por la provincia de Salta y
          además lo acompañó en distintas puestas en escena de la Misa Criolla
          con Ariel Ramírez, Domingo Cura y el Cuarteto Los Andes. Actuó en el
          Colegio de Abogados de la Capital Federal y en el “Canto a Pehuajó” de
          la provincia de Buenos Aires. Participó con las Voces de Orán de un
          espectáculo realizado en el Delmi en la ciudad de Salta y en el Club
          Gimnasia de San Ramón de la Nueva Orán, donde participaron “El
          Chúcaro” y Norma Viola, junto al Chango Nieto.
        </p>
        <p>
          Su primera actuación en teatro, fue en el de la Fábula, en Buenos
          Aires, a metros del Mercado de Abasto, donde participó junto a los
          poetas Armando Tejada Gómez, Daniel Prado y Jorge Propato. El
          intérprete de las canciones era Moncho Mieres. En el teatro Hif
          compartió el escenario junto a los poetas Armando Tejada Gómez y
          Hamlet Lima Quintana. Junto a Zamba Quipildor estuvo en todas las
          presentaciones de la obra integral “Güemes Eterno”, que se realizó en
          el Teatro del Huerto, en el de la Ciudad de Salta y en el Opera de la
          Avenida Corrientes.
        </p>
        <p>
          Realizó un Café Concert junto a José Gallardo, Ariel Petroccelli,
          Isamara y Rodolfo Soria.
          <br />
          Se inició en el periodismo en el año 1964 con un ciclo radial emitido
          por LRA4 Radio Nacional Salta, emisora que lo contó por casi cuatro
          décadas. Desde esta emisora organizó un microprograma denominado “La
          gotita literaria” dedicado a difundir a poetas y escritores del
          Noroeste argentino, se reproducía en cincuenta y dos emisoras del país
          en forma diaria, siendo tan cotizados esos pocos minutos que muchas
          emisoras los programaban hasta cuatro veces por día. En 1967
          incursionó en la famosa Radio Porteña por invitación personal de su
          propietario don Ricardo Bernoti. En Radio Nacional Buenos Aires
          realizaba programas en vivo con los grandes intérpretes de la música
          argentina. En la década del setenta trabajó en Radio Libertador de
          Mendoza. Luego de vuelta por Buenos Aires, en Radio El Mundo.
          Posteriormente y ya radicado definitivamente en Salta, se desempeñó en
          numerosas emisoras de la ciudad de Salta, entre ellas: LV9 Radio
          Salta, FM 100, FM Cerebro, FM Patria, etc. En este período, sus
          programas más reconocidos fueron Tiempo Cancionero, emitido por Radio
          Nacional Salta; Latinoamericano y El Duende de Salta.
        </p>
        <p>
          Su actividad en medios escritos se inicia como corrector del Diario El
          Tribuno de Salta cuando la sede del mismo se encontraba en calle Deán
          Funes Nº 92, a comienzos de la década del setenta. Luego escribe para
          El Civismo de Luján y en 1971 en el Oeste Semanal de San Justo, ambos
          de la provincia de Buenos Aires, donde además dirigía el suplemento
          literario de La Mano con el Arte. En 1974 pertenece a la redacción del
          Diario El Intransigente de Salta. En 1977 se desempeña en diario
          Mendoza, de la localidad homónima y en el semanario La Provincia de la
          misma ciudad cuyana. También allí trabaja para la revista de
          investigación científica CIEN´S. Actuó como corresponsal de la revista
          libro de humanidades “Dialogue”, que dirigía Albert Eiguer, en París
          Francia. En el diario El Tiempo de Salta dirigió un suplemento
          literario denominado “La Gauchita en el Tiempo”.
        </p>
        <p>
          También se destacó en la labor televisiva donde se inició siendo muy
          joven, bajo la dirección de Don Ángel Longarte, quien lo llevó a
          participar de varios programas televisivos. Animó en 1968 en un
          espectáculo que se realizaba en un canal de circuito cerrado. Entre
          1986 y 1993, conduce y produce el programa aquí los Valles
          Calchaquíes. En 1974, conduce junto a Eduardo Tuna Esper un programa
          folclórico que se emitía por Canal 11 de Salta. Además colaboró en
          muchos otros programas, como Bingo Gira, donde participaba como jurado
          de un concurso de preguntas y respuestas para el estudiantado salteño,
          ciclo que se emitía por Canal 11 de Salta.
        </p>
        <p>
          En su ciudad natal y como director del Instituto Cultural Andino,
          edita numerosos libros. En 1985, publica la revista Libro De La Mano
          con el Arte, donde incluye a los más notables escritores del Noroeste
          argentino. En 1987 edita y dirige Logos, revista-libro con la
          producción de los más notables escritores del Noroeste argentino,
          publicación que aportó tres números. Publicó durante diez años
          consecutivos la revista mensual “La Gauchita”, que le valió numerosos
          reconocimientos en el país y mediante la cual mantenía contacto
          permanente con editoriales y bibliotecas de diferentes lugares del
          mundo. Asimismo, el libro La Música, autoría de Rubén Chame. Es autor
          de un trabajo de investigación histórica denominado “Conozca la
          Historia de Salta a través de sus efemérides”, libro que publica en
          1993.Escribió también “Poetas Salteños en el Congreso Nacional”, un
          trabajo antológico y de estudio de la literatura salteña, publicándolo
          en 1997. En el año 2006 edita el CD “La Palabra”, en el que se
          incluyen sus poemas y canciones interpretadas por zamba Quipildor y
          Rubén Pérez. En el año 2007 presenta dos trabajos literarios: el
          poemario “Per Saecula Saeculorum – Amen” y el monólogo “Por Amor a la
          Vida”. Al año siguiente, 2008, presenta en el Teatro del Huerto
          (Salta) un trabajo discográfico denominado “Frutos de la Memoria”.
        </p>
        <p>
          Prologó diversos libros, entre ellos: “Socava El Amor”, de Mirtha de
          Wesler en 1986; “Amoralgos” de Antonio Vilariño en 1987; “Poemas
          Transoceánicos” de Rubén Pérez y Chus Feteira en 1993.
        </p>
        <p>
          En 2005 editorial Baudino publica su novela El Inca-Paz, obra mediante
          la cual defiende la argentinidad. Se trata de un complejo trabajo
          donde integra sus saberes filosóficos, literarios, geográficos e
          históricos.
        </p>
        <p>
          Cuenta además con varios trabajos de investigación, poemas y novelas,
          aún sin publicar.
          <br />
          Se desempeñó como jurado en diferentes eventos: corsos, concursos
          literarios y musicales (tanto de cantos como instrumentales).
        </p>
        <p>
          Representó a la provincia de Salta en diferentes delegaciones
          culturales que actuaron en distintos lugares de Chile, Bolivia y
          Paraguay.
        </p>
        <p>
          A lo largo de toda su carrera mantuvo una coherencia notable en su
          tarea por mantener vigente nuestro acervo cultural, siendo reconocido
          como un magnánimo defensor del folclore y la cultura de Salta. Fue
          merecedor de numerosos reconocimientos, premios y distinciones. Entre
          los más importantes el título de “Nobleza Meritocrática y la
          Distinción “Estrella Académica Universal en carácter de Patricio de la
          Humanidad Solidaria” en la 135ª Honra Pública de Valores Sociales,
          acreditándose su rango en el Registro Mundial de Valores del Género
          Humano (en los folios Nº 278/279), otorgados ambos por la Organización
          Mundial de Pueblos y Culturas, la Obra Mundial Pro-Humanidad
          Solidaria, la Transacademia, y el Instituto Universal de las Naciones.
        </p>
        <p>
          Sus trabajos literarios son altamente valorados por las más
          prestigiosas Universidades del mundo.{" "}
          <a href="http://spanish.ecc.u-tokyo.ac.jp/aries/amorvida">
            En la página web de la Universidad de Tokio
          </a>
          , se publicaron algunos de sus escritos y material periodístico. A su
          vez, en el portal literario que habilitaron conjuntamente las
          Universidades de Texas, Dakota del Norte y la Universidad Argentina J.
          F. Kennedy, la reviste{" "}
          <a href="http://actaliteraria.blogspot.com/2009/07/eduardo-ceballos.html">
            Analecta Literaria
          </a>
          , se pueden conocer las producciones literarias de este poeta salteño.
          Recientemente, la Universidad de Udine, Italia, lo convocó para
          participar en calidad de ponente en el Congreso Internacional “La
          alimentación como patrimonio cultural de la emigración en las
          Américas”.
          <br />
          En el mes de Octubre de 2009 participó en el Encuentro Internacional
          de Escritores, que año a año se realiza en Tarija, congregando a los
          más prestigiosos literatos de Latinoamérica. En esa ocasión se le
          otorgó el reconocimiento de Visitante Ilustre de la Ciudad de Tarija.
        </p>
        <p>
          Continúa luchando por los valores que siempre lo identificaron y que
          fueron la fuente de inspiración de todas sus obras.
        </p>
        <p></p>
      </div>
    </Layout>
  )
}

export default Home
